import LanguageService from "../service/LanguageService";
let ValidService = {};
ValidService.required = {
  required: true,
  message: LanguageService?.lang?.valid_required ?? "valid_required",
  trigger: "blur",
};
ValidService.requiredChange = {
  required: true,
  message: LanguageService?.lang?.valid_requiredChange ?? "valid_requiredChange",
  trigger: "change",
};
ValidService.borderRadius = {
  pattern: /^[0-9,]+$/g,
  message: LanguageService?.lang?.t_number_by_space_with_commas ?? "t_number_by_space_with_commas",
  trigger: "blur",
};
ValidService.checkAccount = {
  pattern: /^[0-9a-zA-Z._@]+$/g,
  message: LanguageService?.lang?.valid_checkAccount ?? "valid_checkAccount",
  trigger: "blur",
};
ValidService.checkCode = {
  pattern: /^[a-zA-Z][a-zA-Z0-9_]*$/,
  message: LanguageService?.lang?.valid_checkCode ?? "valid_checkCode",
  trigger: "blur",
};
ValidService.checkNumber = {
  pattern: /^[0-9]+$/g,
  message: LanguageService?.lang?.valid_checkNumber ?? "valid_checkNumber",
  trigger: "blur",
};
ValidService.checkNoSpace = {
  pattern: /^\S*$/,
  message: LanguageService?.lang?.valid_checkNoSpace ?? "valid_checkNoSpace",
  trigger: "blur",
};
ValidService.checkNumberInteger = {
  pattern: /^[-*0-9]+$/g,
  message: LanguageService?.lang?.valid_checkNumber_integer ?? "valid_checkNumber_integer",
  trigger: "blur",
};
ValidService.checkPhone = {
  pattern: /^(0|\+?84|0084)\d{9}$/,
  message: LanguageService?.lang?.valid_checkPhone ?? "valid_checkPhone",
  trigger: "blur",
};
ValidService.checkPhone10_11Number = {
  pattern: /^0[0-9]{9,10}$/,
  message: LanguageService?.lang?.valid_checkPhone10_11Number ?? "valid_checkPhone10_11Number",
  trigger: "blur",
};
ValidService.checkSpaceString = {
  pattern: /\S/g,
  message: LanguageService?.lang?.valid_checkSpaceString ?? "valid_checkSpaceString",
  trigger: "blur",
};
ValidService.checkNameAllowVN = {
  pattern:
    /^[0-9a-zA-Z_ ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìiíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]+$/,
  message: LanguageService?.lang?.valid_checkNameAllowVN ?? "valid_checkNameAllowVN",
  trigger: "blur",
};
ValidService.checkMaxLength = (max) => {
  return {
    max: max,
    message: LanguageService?.lang?.valid_characterMax ?? "valid_characterMax" + max,
    trigger: "blur",
  };
};
ValidService.checkMinLength = (min) => {
  return {
    min: min,
    message: LanguageService?.lang?.valid_characterMin ?? "valid_characterMin" + min,
    trigger: "blur",
  };
};
ValidService.checkNumberFloatNotNegative = {
  pattern: /^[\d.]+$/,
  message: LanguageService?.lang?.valid_checkNumberFloatNotNegative ?? "valid_checkNumberFloatNotNegative",
  trigger: "blur",
};
ValidService.checkNumberPositive = {
  pattern: /^[-]?[\d]+$/,
  message: LanguageService?.lang?.valid_checkNumberInteger ?? "valid_checkNumberInteger",
  trigger: "blur",
};
ValidService.number0100 = {
  pattern: /^[0-9]{1,2}$/,
  message: LanguageService?.lang?.valid_number0100 ?? "valid_number0100",
  trigger: "blur",
};
ValidService.checkEmail = {
  pattern:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  message: LanguageService?.lang?.valid_checkEmail ?? "valid_checkEmail",
  trigger: "blur",
};
ValidService.checkAz09 = {
  pattern: /^[\w]+$/g,
  message: LanguageService?.lang?.['t_a-z_0-9'] ?? "t_a-z_0-9",
  trigger: "blur",
};
ValidService.checkURL = {
  pattern:
    /^(https?:)?\/\/((([a-z0-9-]+\.)+[a-z]{2,5})|(((1?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(1?\d{1,2}|2[0-4]\d|25[0-5])))(:\d{1,5})?($|\/|\?|#)/,
  message: LanguageService?.lang?.valid_checkUrl ?? "valid_checkUrl",
  trigger: "blur",
};
ValidService.checkServer = {
  pattern: /^[a-z0-9-.]*$/,
  message: LanguageService?.lang?.valid_checkServer ?? "valid_checkServer",
  trigger: "blur",
};
ValidService.checkDatabaseName = {
  pattern: /^\w+$/,
  message: LanguageService?.lang?.valid_checkDatabaseName ?? "valid_checkDatabaseName",
  trigger: "blur",
};
ValidService.checkSpecial = {
  pattern: /^\w+$/,
  message: LanguageService?.lang?.valid_check_special ?? "valid_check_special",
  trigger: "blur",
};
ValidService.checkPolygonNoneHTML = {
  pattern:
    /^\[\[\[\d+(\.\d+)?,\d+(\.\d+)?\](,\[\d+(\.\d+)?,\d+(\.\d+)?\]){2,}\](,\[\[\d+(\.\d+)?,\d+(\.\d+)?\](,\[\d+(\.\d+)?,\d+(\.\d+)?\]){2,}\])*\]$/,
  message: LanguageService?.lang?.t_wrong_polygon ?? "t_wrong_polygon",
  trigger: "blur",
};
ValidService.checkPolygonWithHTML = {
  pattern:
    /^<MultiGeometry>(<Polygon><outerBoundaryIs><LinearRing><coordinates>\d+(\.\d+)?,\d+(\.\d+)?( \d+(\.\d+)?,\d+(\.\d+)?){2,}<\/coordinates><\/LinearRing><\/outerBoundaryIs><\/Polygon>)+<\/MultiGeometry>$/,
  message: LanguageService?.lang?.t_wrong_polygon_html ?? "t_wrong_polygon_html",
  trigger: "blur",
};
ValidService.sql = {
  pattern: "@table",
  message: LanguageService?.lang?.['t-place-sql'] ?? "t-place-sql",
  trigger: "blur",
};
export default ValidService;