import { defineStore } from "pinia";
import mushroom from "cem-probe-api";
import { abortAPI } from "@/stores/abortAPI";

const abortController = new AbortController();
const storeAbortAPI = abortAPI();

export const ListService = defineStore({
  id: "listService",
  state: () => ({
    data: [],
  }),
  actions: {
    async getAllListService() {
      try {
        storeAbortAPI.setAbort(abortController);
        const response = await mushroom.service.getAllAsync(
          {
            filters: ["type=" + localStorage.getItem("type_login")],
          },
          { enabledCache: false },
          {
            abortController: abortController
          }
        );
        if (response.result) {
          this.data = response.result;
        }
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },
  },
  getters: {
    showListService: (state) => {
      return state.data;
    },
  },
});
